export const accounts = [
  { value: 'Selecione', label: 'Selecione' },
  { label: '006 : TAXA DE MUDANÇA DE ENDEREÇO', value: '006 : TAXA DE MUDANÇA DE ENDEREÇO' },
  { label: '010 : Equipamento Danificado', value: '010 : Equipamento Danificado' },
  { label: '012 : Multa contratual', value: '012 : Multa contratual' },
  { label: '013 : Roteador', value: '013 : Roteador' },
  { label: '015 : Evento Temporário', value: '015 : Evento Temporário' },
  { label: '02.09 : Técnicos', value: '02.09 : Técnicos' },
  { label: '02.10 : Transporte', value: '02.10 : Transporte' },
  { label: '02.11 : Alimentação', value: '02.11 : Alimentação' },
  { label: '02.12 : Loja', value: '02.12 : Loja' },
  { label: 'Mensalidade', value: 'Mensalidade' },
  { label: 'Taxa de instalação', value: 'Taxa de instalação' },
];

export const receivingPoints = [
  { value: 'Selecione', label: 'Selecione' },
  { label: 'BANCO INTER TRANCOSO', value: 'BANCO INTER TRANCOSO' },
  { label: 'BANCO ITAÚ CABRÁLIA', value: 'BANCO ITAÚ CABRÁLIA' },
  { label: 'Gerencianet', value: 'Gerencianet' },
  { label: 'Loja', value: 'Loja' },
];

export const receivingTypes = [
  { value: 'Selecione', label: 'Selecione' },
  { label: 'Cartão de Crédito', value: 'Cartão de Crédito' },
  { label: 'Cartão de Débito', value: 'Cartão de Débito' },
  { label: 'Cheque à Vista', value: 'Cheque à Vista' },
  { label: 'Dinheiro', value: 'Dinheiro' },
  { label: 'PIX', value: 'PIX' },
  { label: 'Transferência Bancária', value: 'Transferência Bancária' },
];

export const months = [
  { value: 'Selecione', label: 'Selecione' },
  { value: '8/2024', label: '8/2024' },
  { value: '7/2024', label: '7/2024' },
  { value: '6/2024', label: '6/2024' },
];

export const cities = [
  { value: 'Selecione', label: 'Selecione' },
  { label: 'Arraial', value: 'Arraial' },
  { label: 'Barrolandia', value: 'Barrolandia' },
  { label: 'Belmonte', value: 'Belmonte' },
  { label: 'Cabrália', value: 'Cabrália' },
  { label: 'Coroa Vermelha', value: 'Coroa Vermelha' },
  { label: 'Eunápolis', value: 'Eunápolis' },
  { label: 'Itabela', value: 'Itabela' },
  { label: 'Itagimirim', value: 'Itagimirim' },
  { label: 'Itapebi', value: 'Itapebi' },
  { label: 'Porto Seguro', value: 'Porto Seguro' },
  { label: 'Santo Antonio', value: 'Santo Antonio' },
  { label: 'Trancoso', value: 'Trancoso' },
];

export const types = [
  { value: 'Selecione', label: 'Selecione' },
  { value: 'Entradas', label: 'Entradas' },
  { value: 'Saídas', label: 'Saídas' },
];

export const financesTabOptions = {
  input: {
    tabId: 0,
    param: 'entradas',
  },
  output: {
    tabId: 1,
    param: 'saidas',
  },
};
