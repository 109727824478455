import React, { useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import {
  DashboardCard,
  DashboardCardContent,
  DashboardCardTitle,
  NoData,
  SectionContainer,
  SectionTitle,
} from './styles';
import check_circle_outline from '../../../images/dashboardCardsImages/check_circle_outline.png';
import Chart from 'react-apexcharts';
import CustomSelect from '../../../shared/components/select';
import { defaultColors } from '../../../styles/theme';
import {
  accounts,
  cities,
  financesTabOptions,
  months,
  receivingPoints,
  receivingTypes,
  types,
} from '../constants/constants';
import { data } from '../seeds/seeds';
import { formatCurrency, parseDate, parseValue } from '../../../shared/util/functions';
import TabBar from '../components/tabBar';

const DashboardFinances = () => {
  let chartDefault = {
    options: {
      xaxis: {
        categories: [],
        labels: {
          formatter: function (value: number): string {
            return value.toLocaleString('pt-BR');
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      colors: [defaultColors.purple.light],
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return val.toLocaleString('pt-BR');
        },
        style: {
          colors: ['#000000'],
        },
      },
    },
    series: [],
  };

  let pieChartDefault = {
    series: [],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: 'right',
        offsetY: 0,
        height: 230,
      },
      labels: [],
    },
  };

  const [accountChart, setAccountChart] = useState<any>(chartDefault);
  const [accountOutputChart, setAccountOutputChart] = useState<any>(chartDefault);
  const [cityChart, setCityChart] = useState<any>(chartDefault);
  const [cityOutputChart, setCityOutputChart] = useState<any>(chartDefault);
  const [receivingTypeChart, setReceivingTypeChart] = useState<any>(pieChartDefault);
  const [receivingTypeOutputChart, setReceivingTypeOutputChart] = useState<any>(pieChartDefault);
  const [receivingPointChart, setReceivingPointChart] = useState<any>(pieChartDefault);
  const [periodChart, setPeriodChart] = useState<any>(pieChartDefault);
  const [periodOutputChart, setPeriodOutputChart] = useState<any>(pieChartDefault);

  //Selects
  const [account, setAccount] = useState<string>('');
  const [receivingPoint, setReceivingPoint] = useState<string>('');
  const [receivingType, setReceivingType] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [city, setCity] = useState<string>('');

  //Options
  const [accountsOptions, setAccountsOptions] = useState(accounts);

  //Dados do dashboard
  const [totalInput, setTotalInput] = useState<string>('0');
  const [totalOutput, setTotalOutput] = useState<string>('0');
  const [result, setResult] = useState<string>('0');

  //Tabs
  const [tabValue, setTabValue] = useState(financesTabOptions.input.tabId);

  useEffect(() => {
    if (tabValue === financesTabOptions.input.tabId) {
      const newOptions = accounts.filter((item) => accountChart.options.xaxis.categories.includes(item.label));
      setAccountsOptions(newOptions);
    } else {
      const newOptions = accounts.filter((item) => accountOutputChart.options.xaxis.categories.includes(item.label));
      setAccountsOptions(newOptions);
    }
  }, [tabValue, accountChart, accountOutputChart]);

  useEffect(() => {
    //Gráfico período
    const monthSeries = months.map((account) => account.label);
    const monthValues: number[] = [];
    const monthSeriesFinal: string[] = [];
    const monthOutputValues: number[] = [];
    const monthOutputSeriesFinal: string[] = [];

    for (const monthItem of monthSeries) {
      const totalInput = data.reduce((sum, item) => {
        return sum + (item.input && monthItem === item.month ? parseValue(item.input) : 0);
      }, 0);
      const totalOutput = data.reduce((sum, item) => {
        return sum + (item.output && monthItem === item.month ? parseValue(item.output) : 0);
      }, 0);
      if (totalInput) {
        monthValues.push(Number(totalInput.toFixed(0)));
        monthSeriesFinal.push(monthItem);
      }
      if (totalOutput) {
        monthOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        monthOutputSeriesFinal.push(monthItem);
      }
    }

    const combinedMonthData = monthSeriesFinal.map((label: any, index: any) => ({
      label,
      value: monthValues[index],
    }));

    const sortedMonthData = combinedMonthData.sort((a, b) => Number(parseDate(a.label)) - Number(parseDate(b.label)));
    const sortedMonthDataLabels = sortedMonthData.map((item) => item.label);
    const sortedMonthDataSeries = sortedMonthData.map((item) => item.value);

    setPeriodChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedMonthDataLabels },
        colors: [defaultColors.purple.light],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
        },
        title: {
          text: 'Histórico mensal',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedMonthDataSeries,
        },
      ],
    }));

    const combinedMonthOutputData = monthOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: monthOutputValues[index],
    }));

    const sortedMonthOutputData = combinedMonthOutputData.sort(
      (a, b) => Number(parseDate(a.label)) - Number(parseDate(b.label)),
    );
    const sortedMonthOutputDataLabels = sortedMonthOutputData.map((item) => item.label);
    const sortedMonthOutputDataSeries = sortedMonthOutputData.map((item) => item.value);

    setPeriodOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedMonthOutputDataLabels },
        colors: [defaultColors.purple.light],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
        },
        title: {
          text: 'Histórico mensal',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de saídas',
          data: sortedMonthOutputDataSeries,
        },
      ],
    }));

    //Graficos de plano de contas
    const accountsSeries = accounts.map((account) => account.label);
    const accountsValues: number[] = [];
    const accountsSeriesFinal: string[] = [];
    const accountsOutputValues: number[] = [];
    const accountsOutputSeriesFinal: string[] = [];

    for (const accountItem of accountsSeries) {
      const totalInput = data.reduce((sum, item) => {
        return sum + (item.input && accountItem === item.account ? parseValue(item.input) : 0);
      }, 0);
      const totalOutput = data.reduce((sum, item) => {
        return sum + (item.output && accountItem === item.account ? parseValue(item.output) : 0);
      }, 0);
      if (totalInput) {
        accountsValues.push(Number(totalInput.toFixed(0)));
        accountsSeriesFinal.push(accountItem);
      }
      if (totalOutput) {
        accountsOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        accountsOutputSeriesFinal.push(accountItem);
      }
    }

    const combinedAccountData = accountsSeriesFinal.map((label: any, index: any) => ({
      label,
      value: accountsValues[index],
    }));

    const sortedAccountData = combinedAccountData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedAccountLabels = sortedAccountData.map((item) => item.label);
    const sortedAccountDataSeries = sortedAccountData.map((item) => item.value);

    setAccountChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedAccountLabels },
        title: {
          text: 'Plano de contas',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedAccountDataSeries,
        },
      ],
    }));

    const combinedOutputAccountData = accountsOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: accountsOutputValues[index],
    }));

    const sortedOutputAccountData = combinedOutputAccountData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedOutputAccountLabels = sortedOutputAccountData.map((item) => item.label);
    const sortedOutputAccountDataSeries = sortedOutputAccountData.map((item) => item.value);

    setAccountOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedOutputAccountLabels },
        title: {
          text: 'Plano de contas',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de saídas',
          data: sortedOutputAccountDataSeries,
        },
      ],
    }));

    //Graficos de cidades
    const citySeries = cities.map((item) => item.label);
    const cityValues: number[] = [];
    const citySeriesFinal: string[] = [];
    const cityOutputValues: number[] = [];
    const cityOutputSeriesFinal: string[] = [];

    for (const cityItem of citySeries) {
      const totalInput = data.reduce((sum, item) => {
        return sum + (item.input && cityItem === item.city ? parseValue(item.input) : 0);
      }, 0);
      const totalOutput = data.reduce((sum, item) => {
        return sum + (item.output && cityItem === item.city ? parseValue(item.output) : 0);
      }, 0);
      if (totalInput) {
        cityValues.push(Number(totalInput.toFixed(0)));
        citySeriesFinal.push(cityItem);
      }
      if (totalOutput) {
        cityOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        cityOutputSeriesFinal.push(cityItem);
      }
    }

    const combinedCityData = citySeriesFinal.map((label: any, index: any) => ({
      label,
      value: cityValues[index],
    }));

    const sortedCityData = combinedCityData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedCityLabels = sortedCityData.map((item) => item.label);
    const sortedCityDataSeries = sortedCityData.map((item) => item.value);

    setCityChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: {
          categories: sortedCityLabels,
          labels: {
            show: false,
          },
        },
        title: {
          text: 'Cidade',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedCityDataSeries,
        },
      ],
    }));

    const combinedCityOutputData = cityOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: cityOutputValues[index],
    }));

    const sortedCityOutputData = combinedCityOutputData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedCityOutputLabels = sortedCityOutputData.map((item) => item.label);
    const sortedCityOutputDataSeries = sortedCityOutputData.map((item) => item.value);

    setCityOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: {
          categories: sortedCityOutputLabels,
          labels: {
            show: false,
          },
        },
        title: {
          text: 'Cidade',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de Saídas',
          data: sortedCityOutputDataSeries,
        },
      ],
    }));

    //Gráficos de tipos de pagamento
    const receivingTypesSeries = receivingTypes.map((item) => item.label);
    const receivingTypesValues: number[] = [];
    const receivingTypesSeriesFinal: string[] = [];
    const receivingTypesOutputValues: number[] = [];
    const receivingTypesOutputSeriesFinal: string[] = [];

    for (const receivingTypeItem of receivingTypesSeries) {
      const totalInput = data.reduce((sum, item) => {
        return sum + (item.input && receivingTypeItem === item.receivingType ? parseValue(item.input) : 0);
      }, 0);
      const totalOutput = data.reduce((sum, item) => {
        return sum + (item.output && receivingTypeItem === item.receivingType ? parseValue(item.output) : 0);
      }, 0);
      if (totalInput) {
        receivingTypesValues.push(Number(totalInput.toFixed(0)));
        receivingTypesSeriesFinal.push(receivingTypeItem);
      }
      if (totalOutput) {
        receivingTypesOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        receivingTypesOutputSeriesFinal.push(receivingTypeItem);
      }
    }

    setReceivingTypeChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingTypesValues,
      options: {
        ...prevState.options,
        labels: receivingTypesSeriesFinal,
        title: {
          text: 'Meio de pagamento',
          align: 'left',
          offsetY: 6,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
    }));

    setReceivingTypeOutputChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingTypesOutputValues,
      options: {
        ...prevState.options,
        labels: receivingTypesOutputSeriesFinal,
        title: {
          text: 'Meio de pagamento',
          align: 'left',
          offsetY: 6,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
    }));

    //Gráfico ponto de recebimento
    const receivingPointsSeries = receivingPoints.map((item) => item.label);
    const receivingPointsValues: number[] = [];
    const receivingPointsSeriesFinal: string[] = [];

    for (const receivingPointItem of receivingPointsSeries) {
      const totalInput = data.reduce((sum, item) => {
        return sum + (item.input && receivingPointItem === item.receivingPoint ? parseValue(item.input) : 0);
      }, 0);
      if (totalInput) {
        receivingPointsValues.push(Number(totalInput.toFixed(0)));
        receivingPointsSeriesFinal.push(receivingPointItem);
      }
    }

    setReceivingPointChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingPointsValues,
      options: {
        ...prevState.options,
        labels: receivingPointsSeriesFinal,
        title: {
          text: 'Ponto de recebimento',
          align: 'left',
          offsetY: 6,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
    }));

    //Totais
    const totalInput = data.reduce((sum, item) => {
      return sum + (item.input ? parseValue(item.input) : 0);
    }, 0);
    const totalOutput = data.reduce((sum, item) => {
      return sum + (item.output ? parseValue(item.output) : 0);
    }, 0);

    setResult(formatCurrency(Number(totalInput) + Number(totalOutput)));
    setTotalInput(formatCurrency(totalInput));
    setTotalOutput(formatCurrency(totalOutput));
  }, []);

  useEffect(() => {
    // if (!account && !receivingType && !receivingPoint && !month && !city) {
    //   return;
    // }

    //Graficos de plano de contas
    const accountsSeries = accounts.map((account) => account.label);
    const accountsValues: number[] = [];
    const accountsSeriesFinal: string[] = [];
    const accountsOutputSeriesFinal: string[] = [];
    const accountsOutputValues: number[] = [];

    for (const accountItem of accountsSeries) {
      let totalInput;
      let totalOutput;
      if (!account || account === 'Selecione') {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.account === accountItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.account === accountItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      } else {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.account === account &&
            item.account === accountItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.account === account &&
            item.account === accountItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      }
      if (totalInput) {
        accountsValues.push(Number(totalInput.toFixed(0)));
        accountsSeriesFinal.push(accountItem);
      }
      if (totalOutput) {
        accountsOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        accountsOutputSeriesFinal.push(accountItem);
      }
    }

    const combinedAccountData = accountsSeriesFinal.map((label: any, index: any) => ({
      label,
      value: accountsValues[index],
    }));

    const sortedAccountData = combinedAccountData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedAccountLabels = sortedAccountData.map((item) => item.label);
    const sortedAccountDataSeries = sortedAccountData.map((item) => item.value);

    setAccountChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedAccountLabels },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedAccountDataSeries,
        },
      ],
    }));

    const combinedOutputAccountData = accountsOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: accountsOutputValues[index],
    }));

    const sortedOutputAccountData = combinedOutputAccountData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedOutputAccountLabels = sortedOutputAccountData.map((item) => item.label);
    const sortedOutputAccountDataSeries = sortedOutputAccountData.map((item) => item.value);

    setAccountOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedOutputAccountLabels },
        title: {
          text: 'Plano de contas',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de saídas',
          data: sortedOutputAccountDataSeries,
        },
      ],
    }));

    //Graficos de Cidade
    const citySeries = cities.map((city) => city.label);
    const cityValues: number[] = [];
    const citySeriesFinal: string[] = [];
    const cityOutputSeriesFinal: string[] = [];
    const cityOutputValues: number[] = [];

    for (const cityItem of citySeries) {
      let totalInput;
      let totalOutput;
      if (!city || city === 'Selecione') {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.city === cityItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.city === cityItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      } else {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.city === city &&
            item.city === cityItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.city === city &&
            item.city === cityItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      }
      if (totalInput) {
        cityValues.push(Number(totalInput.toFixed(0)));
        citySeriesFinal.push(cityItem);
      }
      if (totalOutput) {
        cityOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        cityOutputSeriesFinal.push(cityItem);
      }
    }

    const combinedCityData = citySeriesFinal.map((label: any, index: any) => ({
      label,
      value: cityValues[index],
    }));

    const sortedCityData = combinedCityData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedCityLabels = sortedCityData.map((item) => item.label);
    const sortedCityDataSeries = sortedCityData.map((item) => item.value);

    setCityChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: {
          categories: sortedCityLabels,
          labels: {
            show: false,
          },
        },
        title: {
          text: 'Cidade',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedCityDataSeries,
        },
      ],
    }));

    const combinedCityOutputData = cityOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: cityOutputValues[index],
    }));

    const sortedCityOutputData = combinedCityOutputData.sort((a, b) => Number(b.value) - Number(a.value));
    const sortedCityOutputLabels = sortedCityOutputData.map((item) => item.label);
    const sortedCityOutputDataSeries = sortedCityOutputData.map((item) => item.value);

    setCityOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: {
          categories: sortedCityOutputLabels,
          labels: {
            show: false,
          },
        },
        title: {
          text: 'Cidade',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de Saídas',
          data: sortedCityOutputDataSeries,
        },
      ],
    }));

    //Graficos de meio de pagto
    const receivingTypesSeries = receivingTypes.map((item) => item.label);
    const receivingTypesValues: number[] = [];
    const receivingTypesSeriesFinal: string[] = [];
    const receivingTypesOutputValues: number[] = [];
    const receivingTypesOutputSeriesFinal: string[] = [];

    for (const receivingTypeItem of receivingTypesSeries) {
      let totalInput;
      let totalOutput;
      if (!receivingType || receivingType === 'Selecione') {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.receivingType === receivingTypeItem &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.receivingType === receivingTypeItem &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      } else {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.receivingType === receivingType &&
            item.receivingType === receivingTypeItem &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.receivingType === receivingType &&
            item.receivingType === receivingTypeItem &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      }
      if (totalInput) {
        receivingTypesValues.push(Number(totalInput.toFixed(0)));
        receivingTypesSeriesFinal.push(receivingTypeItem);
      }
      if (totalOutput) {
        receivingTypesOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        receivingTypesOutputSeriesFinal.push(receivingTypeItem);
      }
    }

    setReceivingTypeChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingTypesValues,
      options: {
        ...prevState.options,
        labels: receivingTypesSeriesFinal,
      },
    }));

    setReceivingTypeOutputChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingTypesOutputValues,
      options: {
        ...prevState.options,
        labels: receivingTypesOutputSeriesFinal,
      },
    }));

    //Graficos de ponto de recebimento
    const receivingPointsSeries = receivingPoints.map((item) => item.label);
    const receivingPointsValues: number[] = [];
    const receivingPointsSeriesFinal: string[] = [];

    for (const receivingPointItem of receivingPointsSeries) {
      let totalInput;
      if (!receivingPoint || receivingPoint === 'Selecione') {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.receivingPoint === receivingPointItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
      } else {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.receivingPoint === receivingPoint &&
            item.receivingPoint === receivingPointItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!month || item.month === month || month === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
      }
      if (totalInput) {
        receivingPointsValues.push(Number(totalInput.toFixed(0)));
        receivingPointsSeriesFinal.push(receivingPointItem);
      }
    }

    setReceivingPointChart((prevState: any) => ({
      ...pieChartDefault,
      series: receivingPointsValues,
      options: {
        ...prevState.options,
        labels: receivingPointsSeriesFinal,
      },
    }));

    //Graficos de periodo
    const monthSeries = months.map((item) => item.label);
    const monthValues: number[] = [];
    const monthSeriesFinal: string[] = [];
    const monthOutputValues: number[] = [];
    const monthOutputSeriesFinal: string[] = [];

    for (const monthItem of monthSeries) {
      let totalInput;
      let totalOutput;
      if (!month || month === 'Selecione') {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.month === monthItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);
        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.month === monthItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      } else {
        totalInput = data.reduce((sum, item) => {
          return (
            sum +
            (item.input &&
            item.month === month &&
            item.month === monthItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.input)
              : 0)
          );
        }, 0);

        totalOutput = data.reduce((sum, item) => {
          return (
            sum +
            (item.output &&
            item.month === month &&
            item.month === monthItem &&
            (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
            (!account || item.account === account || account === 'Selecione') &&
            (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
            (!city || item.city === city || city === 'Selecione')
              ? parseValue(item.output)
              : 0)
          );
        }, 0);
      }
      if (totalInput) {
        monthValues.push(Number(totalInput.toFixed(0)));
        monthSeriesFinal.push(monthItem);
      }
      if (totalOutput) {
        monthOutputValues.push(Number((totalOutput * -1).toFixed(0)));
        monthOutputSeriesFinal.push(monthItem);
      }
    }

    const combinedMonthData = monthSeriesFinal.map((label: any, index: any) => ({
      label,
      value: monthValues[index],
    }));

    const sortedMonthData = combinedMonthData.sort((a, b) => Number(parseDate(a.label)) - Number(parseDate(b.label)));
    const sortedMonthDataLabels = sortedMonthData.map((item) => item.label);
    const sortedMonthDataSeries = sortedMonthData.map((item) => item.value);

    setPeriodChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedMonthDataLabels },
        colors: [defaultColors.purple.light],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
        },
        title: {
          text: 'Histórico mensal',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de entradas',
          data: sortedMonthDataSeries,
        },
      ],
    }));

    const combinedMonthOutputData = monthOutputSeriesFinal.map((label: any, index: any) => ({
      label,
      value: monthOutputValues[index],
    }));

    const sortedMonthOutputData = combinedMonthOutputData.sort(
      (a, b) => Number(parseDate(a.label)) - Number(parseDate(b.label)),
    );
    const sortedMonthOutputDataLabels = sortedMonthOutputData.map((item) => item.label);
    const sortedMonthOutputDataSeries = sortedMonthOutputData.map((item) => item.value);

    setPeriodOutputChart((prevState: any) => ({
      ...chartDefault,
      options: {
        ...prevState.options,
        xaxis: { categories: sortedMonthOutputDataLabels },
        colors: [defaultColors.purple.light],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
        },
        title: {
          text: 'Histórico mensal',
          align: 'left',
          offsetY: 12,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: defaultColors.gray.main,
          },
        },
      },
      series: [
        {
          name: 'Total de saídas',
          data: sortedMonthOutputDataSeries,
        },
      ],
    }));

    //Totais
    let resultTotalInput = 0;
    for (const item of data) {
      if (
        (!account || item.account === account || account === 'Selecione') &&
        (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
        (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
        (!month || item.month === month || month === 'Selecione') &&
        (!city || item.city === city || city === 'Selecione')
      ) {
        if (item.input) {
          resultTotalInput += parseValue(item.input);
        }
      }
    }
    setTotalInput(formatCurrency(resultTotalInput));

    let resultTotalOutput = 0;
    for (const item of data) {
      if (
        (!account || item.account === account || account === 'Selecione') &&
        (!receivingType || item.receivingType === receivingType || receivingType === 'Selecione') &&
        (!receivingPoint || item.receivingPoint === receivingPoint || receivingPoint === 'Selecione') &&
        (!month || item.month === month || month === 'Selecione') &&
        (!city || item.city === city || city === 'Selecione')
      ) {
        if (item.output) {
          resultTotalOutput += parseValue(item.output);
        }
      }
    }

    setTotalOutput(formatCurrency(resultTotalOutput));

    setResult(formatCurrency(Number(resultTotalInput) + Number(resultTotalOutput)));
  }, [account, receivingType, receivingPoint, month, city]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setAccount('');
    setReceivingPoint('');
    setReceivingType('');
    setMonth('');
    setCity('');
  };

  return (
    <Box height={'100%'}>
      <PageStructure hideRightColumn={true}>
        <TabBar value={tabValue} handleChange={handleTabChange} />
        <Box mt={'16px'} display={'flex'} gap={'8px'} flexDirection={'column'} width={'100%'}>
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Box gap={'20px'} width={'100%'} display={'flex'} flexDirection={'row'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                gap={'16px'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} gap={'20px'} width={'100%'}>
                  <Box width={'100%'}>
                    <CustomSelect
                      placeholder="Plano de contas"
                      label="Plano de contas"
                      id="account"
                      onChange={(event: any) => {
                        setAccount(event.target.value);
                      }}
                      value={account}
                      options={accountsOptions}
                      fullWidth
                    />
                  </Box>
                  {tabValue === financesTabOptions.input.tabId && (
                    <Box width={'100%'}>
                      <CustomSelect
                        placeholder="Ponto de recebimento"
                        label="Ponto de recebimento"
                        id="receivingPoint"
                        onChange={(event: any) => setReceivingPoint(event.target.value)}
                        value={receivingPoint}
                        options={receivingPoints}
                        fullWidth
                      />
                    </Box>
                  )}
                  <Box width={'100%'}>
                    <CustomSelect
                      placeholder="Meio de pagamento"
                      label="Meio de pagamento"
                      id="receivingType"
                      onChange={(event: any) => setReceivingType(event.target.value)}
                      value={receivingType}
                      options={receivingTypes}
                      fullWidth
                    />
                  </Box>
                  <Box width={'100%'}>
                    <CustomSelect
                      placeholder="Mês"
                      label="Mês"
                      id="month"
                      onChange={(event: any) => setMonth(event.target.value)}
                      value={month}
                      options={months}
                      fullWidth
                    />
                  </Box>
                  <Box width={'100%'}>
                    <CustomSelect
                      placeholder="Cidade"
                      label="Cidade"
                      id="type"
                      onChange={(event: any) => setCity(event.target.value)}
                      value={city}
                      options={cities}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} gap={'20px'} flexDirection={'column'} width={'100%'}>
              <Box display={'flex'} gap={'20px'} mt={'16px'} width={'100%'}>
                <DashboardCard urlImage={check_circle_outline}>
                  <DashboardCardTitle>Total de Entradas</DashboardCardTitle>
                  <DashboardCardContent>{totalInput || 'R$ 0'}</DashboardCardContent>
                </DashboardCard>
                <DashboardCard urlImage={check_circle_outline}>
                  <DashboardCardTitle>Total de Saídas</DashboardCardTitle>
                  <DashboardCardContent>{totalOutput || 'R$ 0'}</DashboardCardContent>
                </DashboardCard>
                <DashboardCard urlImage={check_circle_outline}>
                  <DashboardCardTitle>Resultado</DashboardCardTitle>
                  <DashboardCardContent>{result || 'R$ 0'}</DashboardCardContent>
                </DashboardCard>
              </Box>
              <Box
                display={'flex'}
                gap={'20px'}
                width={'100%'}
                flexDirection={'column'}
                justifyContent={'space-between'}
              >
                <Box width={'100%'} display={'flex'} gap={'20px'}>
                  <Box display={'flex'} gap={'20px'} flexDirection={'column'} width={'65%'} height="100%">
                    <SectionContainer>
                      <Box width={'100%'} height="25vh">
                        <Chart
                          options={
                            tabValue === financesTabOptions.input.tabId
                              ? accountChart.options
                              : accountOutputChart.options
                          }
                          series={
                            tabValue === financesTabOptions.input.tabId
                              ? accountChart.series
                              : accountOutputChart.series
                          }
                          type="bar"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </SectionContainer>
                    <Box display={'flex'} gap={'20px'} height="22vh">
                      <SectionContainer>
                        <Box width={'100%'} height="100%">
                          <Chart
                            options={
                              tabValue === financesTabOptions.input.tabId
                                ? receivingTypeChart.options
                                : receivingTypeOutputChart.options
                            }
                            series={
                              tabValue === financesTabOptions.input.tabId
                                ? receivingTypeChart.series
                                : receivingTypeOutputChart.series
                            }
                            type="pie"
                            width="100%"
                            height="100%"
                          />
                        </Box>
                      </SectionContainer>
                      <SectionContainer>
                        <Box width={'100%'} height="100%">
                          {tabValue === financesTabOptions.input.tabId ? (
                            <Chart
                              options={receivingPointChart.options}
                              series={receivingPointChart.series}
                              type="pie"
                              width="100%"
                              height="100%"
                            />
                          ) : (
                            <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                              <SectionTitle>Ponto de recebimento</SectionTitle>
                              <NoData>-</NoData>
                            </Box>
                          )}
                        </Box>
                      </SectionContainer>
                    </Box>
                  </Box>

                  <Box width={'35%'}>
                    <SectionContainer height="100%">
                      <Box width={'100%'} height="100%">
                        <Chart
                          options={
                            tabValue === financesTabOptions.input.tabId ? cityChart.options : cityOutputChart.options
                          }
                          series={
                            tabValue === financesTabOptions.input.tabId ? cityChart.series : cityOutputChart.series
                          }
                          type="bar"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </SectionContainer>
                  </Box>
                </Box>
                <Box width={'100%'} display={'flex'}>
                  <SectionContainer>
                    <Box flexDirection={'column'} width={'100%'} height="25vh">
                      <Chart
                        options={
                          tabValue === financesTabOptions.input.tabId ? periodChart.options : periodOutputChart.options
                        }
                        series={
                          tabValue === financesTabOptions.input.tabId ? periodChart.series : periodOutputChart.series
                        }
                        type="area"
                        width={'100%'}
                        height="100%"
                      />
                    </Box>
                  </SectionContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageStructure>
    </Box>
  );
};

export default DashboardFinances;
