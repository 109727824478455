/* eslint-disable react/react-in-jsx-scope */
import DashboardIcon from '../images/icons/aside/dashboard';
import ExitIcon from '../images/icons/aside/exit';
import LeadsIcon from '../images/icons/aside/leads';
import ProductsIcon from '../images/icons/aside/products';
import SalesAgentsIcon from '../images/icons/aside/salesAgents';
import CustomerDetails from '../pages/customerDetails/views';
import Customers from '../pages/customers/views';
import Dashboard from '../pages/dashboard/views';
import LeadDetails from '../pages/leadDetails/views';
import Leads from '../pages/leads/views';
import Login from '../pages/login/views';
import ProductDetails from '../pages/productDetails/views';
import Products from '../pages/products/views';
import SalesAgents from '../pages/salesAgents/views';
import DashboardFinances from '../pages/tFinances/views';

interface IRoute {
  name: string;
  path?: string;
  component?: React.ReactElement;
  showOnAsideMenu: boolean;
  private: boolean;
  icon?: React.ReactElement;
  labelAsideMenu?: string;
  onlyForRecurrentCompany?: boolean;
}

interface IRoutes {
  [key: string]: IRoute;
}

export const routes: IRoutes = {
  login: {
    name: 'login',
    path: '/login',
    component: <Login />,
    private: false,
    showOnAsideMenu: false,
  },
  // components: {
  //   name: 'components',
  //   path: '/componentes',
  //   component: <Components />,
  //   private: false,
  //   showOnAsideMenu: false,
  // },
  // dashboard: {
  //   name: 'dashboard',
  //   path: '/dashboard',
  //   component: <Dashboard />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <DashboardIcon fill="white" />,
  //   labelAsideMenu: 'Dashboard',
  // },
  // leads: {
  //   name: 'leads',
  //   path: '/leads',
  //   component: <Leads />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <LeadsIcon fill="white" />,
  //   labelAsideMenu: 'Leads',
  //   onlyForRecurrentCompany: false,
  // },
  // customers: {
  //   name: 'customers',
  //   path: '/clientes',
  //   component: <Customers />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <LeadsIcon fill="white" />,
  //   labelAsideMenu: 'Clientes',
  //   onlyForRecurrentCompany: true,
  // },
  // leadDetails: {
  //   name: 'leadDetails',
  //   path: '/lead-detalhes',
  //   component: <LeadDetails />,
  //   private: true,
  //   showOnAsideMenu: false,
  // },
  // customerDetails: {
  //   name: 'customerDetails',
  //   path: '/cliente-detalhes',
  //   component: <CustomerDetails />,
  //   private: true,
  //   showOnAsideMenu: false,
  // },
  // productDetails: {
  //   name: 'productDetail',
  //   path: '/produto-detalhes',
  //   component: <ProductDetails />,
  //   private: true,
  //   showOnAsideMenu: false,
  // },
  // products: {
  //   name: 'products',
  //   path: '/produtos',
  //   component: <Products />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <ProductsIcon fill="white" />,
  //   labelAsideMenu: 'Produtos',
  // },
  // salesAgents: {
  //   name: 'salesAgents',
  //   path: '/vendedores',
  //   component: <SalesAgents />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <SalesAgentsIcon fill="white" />,
  //   labelAsideMenu: 'Vendedores',
  // },
  finances: {
    name: 'finances',
    path: '/finances',
    component: <DashboardFinances />,
    private: true,
    showOnAsideMenu: true,
    icon: <DashboardIcon fill="white" />,
    labelAsideMenu: 'Financeiro',
  },
  sales: {
    name: 'sales',
    path: '/sales',
    component: <DashboardFinances />,
    private: true,
    showOnAsideMenu: true,
    icon: <DashboardIcon fill="white" />,
    labelAsideMenu: 'Comercial',
  },
  instalation: {
    name: 'instalation',
    path: '/instalation',
    component: <DashboardFinances />,
    private: true,
    showOnAsideMenu: true,
    icon: <DashboardIcon fill="white" />,
    labelAsideMenu: 'Instalação',
  },
  cancel: {
    name: 'cancel',
    path: '/cancel',
    component: <DashboardFinances />,
    private: true,
    showOnAsideMenu: true,
    icon: <DashboardIcon fill="white" />,
    labelAsideMenu: 'Cancelamento',
  },
  exit: {
    name: 'exit',
    private: true,
    showOnAsideMenu: true,
    icon: <ExitIcon fill="white" />,
    labelAsideMenu: 'Sair',
  },
};

export const profileTabOptions = {
  general: {
    tabId: 0,
    param: 'informacoes-gerais',
  },
  profileTest: {
    tabId: 1,
    param: 'teste-de-perfil',
  },
  signature: {
    tabId: 2,
    param: 'assinatura',
  },
};

export const productsTabOptions = {
  list: {
    tabId: 0,
    param: 'listagem',
  },
  traits: {
    tabId: 1,
    param: 'caracteristicas',
  },
};
