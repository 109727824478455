import React, { useContext, useEffect, useState } from 'react';
import { tokenKey, userFromRecurrentCompanyKey, userNameStorage } from '../../util/constants';
import { routes } from '../../../routes/routes';

import { Container, MenuItemLink, LabelItem, ButtonSubItem, LogoContainer } from './styles';
import { useLocation } from 'react-router-dom';
import { mobileBreakPoint } from '../../../styles/theme';
import Logo from '../../../images/logo/aside_logo';
import { Box } from '@mui/material';
import { AuthenticationContext } from '../../../contexts/authentication';

interface IAsideProps {
  expandAsideMenu: boolean;
  setExpandAsideMenu: (arg0: boolean) => void;
}

const Aside: React.FC<IAsideProps> = ({ expandAsideMenu, setExpandAsideMenu }) => {
  const [optionSelected, setOptionSelected] = useState<string>('');
  const { pathname } = useLocation();
  const { user } = useContext(AuthenticationContext);
  const userFromRecurrentCompany =
    Boolean(Number(user?.userFromRecurrentCompany)) ||
    Boolean(Number(localStorage.getItem(userFromRecurrentCompanyKey)));

  const isMobile = window.screen.width <= mobileBreakPoint;

  function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userNameStorage);
    localStorage.removeItem('optionSelected');
    localStorage.removeItem(userFromRecurrentCompanyKey);
    window.location.reload();
  }

  const handleOptionSelected = (option: string) => {
    if (isMobile) {
      setExpandAsideMenu(!expandAsideMenu);
    }
    if (option === routes.exit.name) {
      logout();
      return;
    }
  };

  useEffect(() => {
    const routesArray = Object.values(routes);
    let option = '';
    for (const route of routesArray) {
      if (route.path === pathname) {
        option = route.name;
      }
    }
    setOptionSelected(option);
  }, [pathname]);

  return (
    <Container expandAsideMenu={expandAsideMenu}>
      <Box display={'flex'} flexDirection={'column'} gap={'24px'} justifyContent={'space-between'} height={'100%'}>
        <Box>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Box sx={{ paddingTop: '8vh' }}>
            {userFromRecurrentCompany
              ? Object.values(routes).map((route) => {
                  return (
                    route.showOnAsideMenu &&
                    (route.onlyForRecurrentCompany || route.onlyForRecurrentCompany === undefined) && (
                      <Box key={route.path}>
                        <MenuItemLink to={route.path ?? ''}>
                          <ButtonSubItem
                            selected={optionSelected === route.name}
                            onClick={() => handleOptionSelected(route.name)}
                          >
                            {/* {route.icon} */}
                            <LabelItem selected={optionSelected === route.name}>{route.labelAsideMenu}</LabelItem>
                          </ButtonSubItem>
                        </MenuItemLink>
                      </Box>
                    )
                  );
                })
              : Object.values(routes).map((route) => {
                  return (
                    route.showOnAsideMenu &&
                    !route.onlyForRecurrentCompany && (
                      <Box key={route.path}>
                        <MenuItemLink to={route.path ?? ''}>
                          <ButtonSubItem
                            selected={optionSelected === route.name}
                            onClick={() => handleOptionSelected(route.name)}
                          >
                            {route.icon}
                            <LabelItem selected={optionSelected === route.name}>{route.labelAsideMenu}</LabelItem>
                          </ButtonSubItem>
                        </MenuItemLink>
                      </Box>
                    )
                  );
                })}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Aside;
