export const data = [
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '226,66',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1186,67',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '120',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1386,67',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '366,67',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1393,34',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '240',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1240',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '220',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1246,66',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '186,67',
    output: '',
  },
  {
    city: 'Arraial',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1226,66',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '20',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '20',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '220',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '20',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Arraial',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '240',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '569,99',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '41,66',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '197,31',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '921,99',
    output: '',
  },
  {
    city: 'Arraial',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '115,64',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '800',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '600',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1340',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '280',
    output: '',
  },
  {
    city: 'Arraial',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '380',
    output: '',
  },
  {
    city: 'Arraial',
    account: '015 : Evento Temporário',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '180',
    output: '',
  },
  {
    city: 'Arraial',
    account: '015 : Evento Temporário',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '80',
    output: '',
  },
  {
    city: 'Arraial',
    account: '015 : Evento Temporário',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '80',
    output: '',
  },
  {
    city: 'Arraial',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-40',
  },
  {
    city: 'Arraial',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-19',
  },
  {
    city: 'Arraial',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-15',
  },
  {
    city: 'Arraial',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-2307,21',
  },
  {
    city: 'Arraial',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-2123,24',
  },
  {
    city: 'Arraial',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1584,93',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '16316,67',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '146181,33',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '15712,68',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '94067,04',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '2045,75',
    output: '-59,99',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '139,98',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '17943,56',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '157569,03',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '14925,49',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '106419,78',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '519,93',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '18486,39',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '147857,34',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '13342,29',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '103724,16',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '2275,78',
    output: '-59,99',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '350',
    output: '-50',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '650',
    output: '-50',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '350',
    output: '-50',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '2300',
    output: '-300',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '600',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1100',
    output: '-50',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1900',
    output: '-50',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '400',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1150',
    output: '-100',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '250',
    output: '',
  },
  {
    city: 'Arraial',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '2450',
    output: '-100',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '160',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '60',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '60',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '40',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '360',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '340',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '80',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '015 : Evento Temporário',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '59,99',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-31,99',
  },
  {
    city: 'Barrolandia',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-530',
  },
  {
    city: 'Barrolandia',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-980,5',
  },
  {
    city: 'Barrolandia',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-500',
  },
  {
    city: 'Barrolandia',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-224',
  },
  {
    city: 'Barrolandia',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-510',
  },
  {
    city: 'Barrolandia',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1720',
  },
  {
    city: 'Barrolandia',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-247',
  },
  {
    city: 'Barrolandia',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-212,82',
  },
  {
    city: 'Barrolandia',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1420,73',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1079,88',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '4203,77',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '14086,05',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '14430,09',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '13877,47',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '219,97',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1535,8',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '4494,07',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '14647,18',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '16906,14',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '13710,21',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '59,99',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1849,76',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '4019,46',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '14786,48',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '16552,29',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '14684,49',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '139,98',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '250',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '250',
    output: '',
  },
  {
    city: 'Barrolandia',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '26',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '164',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '80',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '26,67',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '178',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '173,34',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '10',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '26,67',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '148,66',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '340',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '80',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '41,66',
    output: '',
  },
  {
    city: 'Belmonte',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-41,82',
  },
  {
    city: 'Belmonte',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-15',
  },
  {
    city: 'Belmonte',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-250',
  },
  {
    city: 'Belmonte',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-900',
  },
  {
    city: 'Belmonte',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-500',
  },
  {
    city: 'Belmonte',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-75',
  },
  {
    city: 'Belmonte',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-854,24',
  },
  {
    city: 'Belmonte',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-865,3',
  },
  {
    city: 'Belmonte',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-856,61',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1510',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '2694,99',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '12388,44',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '15243,95',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '8311,99',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '79,99',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1567,98',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '3107,99',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '863,89',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '14003,51',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '16970,95',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '8726,95',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1749,98',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '2469,99',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '12776,07',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '15733,4',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '9788,79',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '-50',
  },
  {
    city: 'Belmonte',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Belmonte',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '285',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '60',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '160',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '550',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '340',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '940',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '120',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '480',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '140',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1020',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '540',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '240,01',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1080',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '40',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '126,98',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '99,99',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '208,3',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '280,32',
    output: '',
  },
  {
    city: 'Cabrália',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-420,94',
  },
  {
    city: 'Cabrália',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-224,2',
  },
  {
    city: 'Cabrália',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-582,72',
  },
  {
    city: 'Cabrália',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1200,7',
  },
  {
    city: 'Cabrália',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1031,5',
  },
  {
    city: 'Cabrália',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-830,5',
  },
  {
    city: 'Cabrália',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1068,08',
  },
  {
    city: 'Cabrália',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1340',
  },
  {
    city: 'Cabrália',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1225,44',
  },
  {
    city: 'Cabrália',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-705,92',
  },
  {
    city: 'Cabrália',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-917,21',
  },
  {
    city: 'Cabrália',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-490,12',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '5975,36',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '10259,42',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '79309,99',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '33064,37',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '56393,36',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '1159,89',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '6746,17',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '10563,72',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '2159,58',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '84728,86',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '32490,2',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '67966,86',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '379,86',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '6580,86',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '10644,49',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '81484,07',
    output: '-79,99',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '31316,54',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '64700,67',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '699,92',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '200',
    output: '-50',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '300',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '250',
    output: '-50',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1200',
    output: '-100',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '410',
    output: '-100',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '450',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '-50',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1050',
    output: '-100',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '300',
    output: '-50',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '350',
    output: '-50',
  },
  {
    city: 'Cabrália',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '89',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '140',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '111',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '190',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '180',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '280',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '340',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '262,33',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '125',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-105',
  },
  {
    city: 'Coroa Vermelha',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-75',
  },
  {
    city: 'Coroa Vermelha',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-25',
  },
  {
    city: 'Coroa Vermelha',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-396,05',
  },
  {
    city: 'Coroa Vermelha',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-20,5',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1679,81',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '5761,29',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '24366,72',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8814,32',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '17630,04',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '99,99',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2199,75',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '5475,04',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '26936,6',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '10553,89',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20417,73',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'BANCO ITAÚ CABRÁLIA',
    input: '94,49',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '99,99',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2185,74',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '5566,05',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '25442,49',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8969,56',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '18599,01',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '159,98',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '-50',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '350',
    output: '-50',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Coroa Vermelha',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '300',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '60',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '220',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '531,66',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '366,67',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '280',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '380,01',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '265',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '914,64',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-137,09',
  },
  {
    city: 'Eunápolis',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-103,59',
  },
  {
    city: 'Eunápolis',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-432,99',
  },
  {
    city: 'Eunápolis',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-25',
  },
  {
    city: 'Eunápolis',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-121',
  },
  {
    city: 'Eunápolis',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-675',
  },
  {
    city: 'Eunápolis',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-965',
  },
  {
    city: 'Eunápolis',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-915',
  },
  {
    city: 'Eunápolis',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-944,79',
  },
  {
    city: 'Eunápolis',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-394,93',
  },
  {
    city: 'Eunápolis',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1335,22',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '788,74',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1389,76',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '45017,57',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8872,56',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '36868,64',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '226,88',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '481,85',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1334,79',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50605,09',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '9088,77',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '39115,82',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '480',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '734,92',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1003,82',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '47648,35',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8030,74',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '39697,04',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '539,96',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '400',
    output: '-50',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '200',
    output: '-50',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '800',
    output: '-50',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '-50',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '850',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '300',
    output: '',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '-50',
  },
  {
    city: 'Eunápolis',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '950',
    output: '-50',
  },
  {
    city: 'Itabela',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Itabela',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '86,67',
    output: '',
  },
  {
    city: 'Itabela',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-28,6',
  },
  {
    city: 'Itabela',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-550',
  },
  {
    city: 'Itabela',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-22,8',
  },
  {
    city: 'Itabela',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-277,91',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '75',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '824,23',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '721,91',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '424,95',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '398,62',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1146,51',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '10888,18',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '7670,31',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '12782,59',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '79,99',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '650',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '450',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '750',
    output: '',
  },
  {
    city: 'Itabela',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1050',
    output: '-100',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '130',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '70',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '260',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '260',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '140',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '240',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-10',
  },
  {
    city: 'Itagimirim',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-244',
  },
  {
    city: 'Itagimirim',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-825',
  },
  {
    city: 'Itagimirim',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-500',
  },
  {
    city: 'Itagimirim',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-405',
  },
  {
    city: 'Itagimirim',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-150,45',
  },
  {
    city: 'Itagimirim',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-62',
  },
  {
    city: 'Itagimirim',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-89,94',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '10765,25',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '11856,99',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '9777,45',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '353,94',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '12304,68',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '12460',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '11281,27',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '99,99',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '60',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '11983,1',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '11249,33',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '11728,54',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '79,99',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '250',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Itagimirim',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '140',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '60',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '120',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '180',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '220,01',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '120',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '220',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '80,01',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '160',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '125,99',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '320,98',
    output: '',
  },
  {
    city: 'Itapebi',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-70,5',
  },
  {
    city: 'Itapebi',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-8',
  },
  {
    city: 'Itapebi',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-150',
  },
  {
    city: 'Itapebi',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-100',
  },
  {
    city: 'Itapebi',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-150',
  },
  {
    city: 'Itapebi',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-130',
  },
  {
    city: 'Itapebi',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-210',
  },
  {
    city: 'Itapebi',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-65',
  },
  {
    city: 'Itapebi',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-442',
  },
  {
    city: 'Itapebi',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-66,3',
  },
  {
    city: 'Itapebi',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-111,9',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '20103,35',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '13050,04',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '15139,06',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '373,97',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '24365,89',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '14429,04',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '15810,23',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '199,89',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '22036,59',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '14010,98',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '15730,24',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '344,97',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '-50',
  },
  {
    city: 'Itapebi',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Itapebi',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1051,66',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1226,67',
    output: '-80',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1240',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '120',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1080',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '20',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '140',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1486,66',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '20',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1310',
    output: '-40',
  },
  {
    city: 'Porto Seguro',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '26,67',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '70',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '20',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '374,94',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '291,66',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '208,33',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '199,65',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8,33',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '166,66',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '108,15',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '015 : Evento Temporário',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1200',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-20',
  },
  {
    city: 'Porto Seguro',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-73',
  },
  {
    city: 'Porto Seguro',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-23,5',
  },
  {
    city: 'Porto Seguro',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-129',
  },
  {
    city: 'Porto Seguro',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-50',
  },
  {
    city: 'Porto Seguro',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-268',
  },
  {
    city: 'Porto Seguro',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-50,55',
  },
  {
    city: 'Porto Seguro',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-259,35',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1559,81',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '3942,53',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '119,99',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '124177,84',
    output: '-79,99',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '4680,44',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '76729,83',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '808,9',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2114,4',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '3867,72',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '119,99',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '135451,75',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '5530,71',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '85658,71',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '344,96',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2408,71',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '3914,53',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '119,99',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '131515,69',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '5493,36',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '80114,22',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '504,94',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '800',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '450',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1550',
    output: '-50',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '150',
    output: '-50',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1300',
    output: '-50',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1979,99',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1050',
    output: '-50',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '350',
    output: '',
  },
  {
    city: 'Porto Seguro',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '1800',
    output: '-200',
  },
  {
    city: 'Porto Seguro',
    account: '(vazio)',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-20',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '160',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '66,67',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '186,67',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '306,66',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '160',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '220',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '80,01',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '240',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '40',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '125',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '295,32',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-300',
  },
  {
    city: 'Santo Antonio',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-400',
  },
  {
    city: 'Santo Antonio',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-220',
  },
  {
    city: 'Santo Antonio',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-254',
  },
  {
    city: 'Santo Antonio',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-250',
  },
  {
    city: 'Santo Antonio',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-800',
  },
  {
    city: 'Santo Antonio',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-30,1',
  },
  {
    city: 'Santo Antonio',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-633,75',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2095,71',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '7145,1',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '34852,93',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '8625,86',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '21327,11',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '539,95',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1963,43',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '7772,98',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '37608,82',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '10556,31',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '25054,67',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '79,99',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '2307,69',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '7903,62',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '38566,91',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '9400,76',
    output: '-59,99',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '24864',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '619,92',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '50',
    output: '-50',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '200',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '210',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '-50',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '50',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '150',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '70',
    output: '',
  },
  {
    city: 'Santo Antonio',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '250',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '120',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '310',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '603,34',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '190,02',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '440',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '160',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '480',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '696,67',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '318,53',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '733,34',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '80',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '160',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '546,66',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '40',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '006 : TAXA DE MUDANÇA DE ENDEREÇO',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '999,99',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '010 : Equipamento Danificado',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '010 : Equipamento Danificado',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '010 : Equipamento Danificado',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '60',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '101,66',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '012 : Multa contratual',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '375',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '012 : Multa contratual',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '463,64',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '323,31',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '012 : Multa contratual',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '169,99',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '717',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '580',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '200',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '100',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '013 : Roteador',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '400',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '015 : Evento Temporário',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-290',
  },
  {
    city: 'Trancoso',
    account: '015 : Evento Temporário',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '2200',
    output: '',
  },
  {
    city: 'Trancoso',
    account: '02.09 : Técnicos',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1723,59',
  },
  {
    city: 'Trancoso',
    account: '02.09 : Técnicos',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-824',
  },
  {
    city: 'Trancoso',
    account: '02.09 : Técnicos',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-1388,74',
  },
  {
    city: 'Trancoso',
    account: '02.10 : Transporte',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-38',
  },
  {
    city: 'Trancoso',
    account: '02.10 : Transporte',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-294',
  },
  {
    city: 'Trancoso',
    account: '02.10 : Transporte',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-56',
  },
  {
    city: 'Trancoso',
    account: '02.11 : Alimentação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-168',
  },
  {
    city: 'Trancoso',
    account: '02.11 : Alimentação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-432',
  },
  {
    city: 'Trancoso',
    account: '02.11 : Alimentação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-90',
  },
  {
    city: 'Trancoso',
    account: '02.12 : Loja',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-828,03',
  },
  {
    city: 'Trancoso',
    account: '02.12 : Loja',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-546,08',
  },
  {
    city: 'Trancoso',
    account: '02.12 : Loja',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '',
    output: '-226,77',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '4449,53',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '13190,22',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '119,99',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '68540,36',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '12407,41',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '41356,41',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '6/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '199,98',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '6079,41',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '14565,96',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '219,98',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '73038,05',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '13014,07',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '50497,05',
    output: '-80',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'BANCO INTER TRANCOSO',
    input: '80',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '7/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '99,99',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '5272,77',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '14279,54',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '219,98',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '71200,95',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '11321,16',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '48744,27',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Mensalidade',
    month: '8/2024',
    receivingType: 'Transferência Bancária',
    receivingPoint: 'Gerencianet',
    input: '524,94',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '850',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '600',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Cheque à Vista',
    receivingPoint: 'Loja',
    input: '150',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '1200',
    output: '-50',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '555',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '6/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '2650',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '1000',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '450',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '800',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '300',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '7/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '4122,5',
    output: '-150',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Crédito',
    receivingPoint: 'Loja',
    input: '500',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Cartão de Débito',
    receivingPoint: 'Loja',
    input: '1075',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Gerencianet',
    input: '875',
    output: '-150',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'Dinheiro',
    receivingPoint: 'Loja',
    input: '675,01',
    output: '',
  },
  {
    city: 'Trancoso',
    account: 'Taxa de instalação',
    month: '8/2024',
    receivingType: 'PIX',
    receivingPoint: 'Gerencianet',
    input: '2500',
    output: '-150',
  },
];
