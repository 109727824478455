import { FormHelperText, InputLabel, MenuItem, TextField, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

interface IStyledSelect {
  height?: string;
}

interface IStyledLabel {
  textColor: string;
}

export const StyledSelect = styled(Select)<IStyledSelect>((props) => ({
  height: props.height ? props.height : '48px',
  background: props.theme.palette.white.main,
  borderRadius: '10px',
  fontWeight: 400,
  fontSize: '14px',
  color: props.theme.palette.gray.main,
  paddingLeft: '8px',
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: props.error
        ? `1px solid ${props.theme.palette.red.main}`
        : `1px solid ${props.theme.palette.purple.main}`,
    },
  },
  '&:hover': {
    '&& fieldset': {
      border: props.error ? `1px solid ${props.theme.palette.red.main}` : `1px solid ${props.theme.palette.gray.light}`,
    },
  },
}));

export const StyledLabel = styled(InputLabel)<IStyledLabel>((props) => ({
  color: props.textColor,
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  transition: 'all 0s',

  '&.Mui-focused': {
    color: props.textColor,
  },
}));

export const StyledMenuItem = styled(MenuItem)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: props.selected ? props.theme.palette.purple.main : props.theme.palette.white.main,
  color: props.theme.palette.gray.main,
  paddingTop: '17px',
  paddingBottom: '17px',
  margin: '0px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',

  '&.MuiPaper-root': {
    backgroundColor: 'red',
  },
  '&.MuiButtonBase-root': {
    backgroundColor: props.theme.palette.white.main,
    '&.Mui-selected': {
      backgroundColor: props.theme.palette.purple.main,
      fontWeight: 700,
      fonSize: '14px',
      color: props.theme.palette.white.main,
    },
  },
  '&:hover': {
    backgroundColor: props.theme.palette.purple.main,
    fontWeight: 700,
    fonSize: '14px',
    color: props.theme.palette.white.main,
  },
}));

export const StyledTextInput = styled(TextField)((props) => ({
  backgroundColor: props.theme.palette.white.main,
  '& .MuiInput-underline:before': {
    borderBottomColor: props.theme.palette.gray.main,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: props.theme.palette.purple.main,
  },
  '& input': {
    fontSize: '14px',
    color: props.theme.palette.gray.main,
  },
  '& input::placeholder': {
    marginLeft: '12px',
    color: props.theme.palette.gray.main,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    opacity: 0.6,
  },
}));

export const StyledErrorText = styled(FormHelperText)((props) => ({
  marginTop: '6px',
  marginLeft: '0px',
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '11px',
  color: props.theme.palette.red.main,
}));
