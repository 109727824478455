import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface IDashboardCard {
  urlImage: string;
}

interface ISectionContainer {
  height?: any;
}

export const Container = styled(Box)((props) => ({}));

export const DashboardCard = styled(Box)<IDashboardCard>((props) => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderRadius: '16px',
  // backgroundImage: `url(${props.urlImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  width: '100%',
  backgroundColor: props.theme.palette.purple.main,

  '@media (max-width: 768px)': {
    width: '100%',
    padding: '12px 20px',
  },
}));
  
export const DashboardCardTitle = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '14px',
  fontWeight: '500',

  '@media (max-width: 768px)': {
    fontSize: '10px',
  },
}));
  
export const DashboardCardContent = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '14px',
  fontWeight: '600',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));

export const DashboardCardContentComplement = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '16px',
  fontWeight: '600',
  display: 'flex',
  marginBottom: '6px',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));

export const SectionContainer = styled(Box)<ISectionContainer>((props) => ({
  display: 'flex',
  width: '100%',
  padding: '4px 8px',
  borderRadius: '16px',
  backgroundColor: props.theme.palette.white.main,
  boxShadow: defaultShadow,
  height: props.height,

  '@media (max-width: 768px)': {
    width: '100%',
    padding: '12px 20px',
  },
}));

export const SectionTitle = styled(Typography)((props) => ({
  color: 'rgb(119, 119, 119)',
  fontSize: '12px',
  fontWeight: 'bold',
  fontFamily: 'Helvetica',
  marginTop: '8px',
  marginLeft: '10px',
}));

export const NoData = styled(Typography)((props) => ({
  color: props.theme.palette.purple.light,
  fontSize: '36px',
  fontWeight: 'bold',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  display: 'flex',
  marginBottom: '32px',
}));

