import * as React from 'react';

import FormControl from '@mui/material/FormControl';

import { StyledErrorText, StyledLabel, StyledMenuItem, StyledSelect, StyledTextInput } from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckIcon from '../../../images/icons/check';
import { useEffect, useState } from 'react';

interface ISelectOption {
  value: string | number;
  label: string;
}

interface ICustomSelectProps {
  fullWidth?: boolean;
  minWidth?: number | string;
  options: ISelectOption[] | string[];
  value: string | undefined | string[] | any;
  onChange: (event: any) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
  height?: string;
  id?: string;
  multiple?: boolean;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  fullWidth,
  minWidth,
  options,
  value,
  onChange,
  placeholder,
  label,
  disabled,
  errorMessage,
  onBlur,
  height,
  id,
  multiple,
}) => {
  const [borderColor, setBorderColor] = useState(errorMessage ? defaultColors.red.main : defaultColors.gray.main);
  const newOptions: ISelectOption[] = options.map((item) => {
    if (typeof item === 'string') {
      return { value: item, label: item };
    } else {
      return item;
    }
  });

  useEffect(() => {
    setBorderColor(!!errorMessage && !value ? defaultColors.red.main : defaultColors.gray.main);
  }, [errorMessage, value]);

  return (
    <FormControl sx={{ minWidth: fullWidth ? '100%' : minWidth }}>
      {value && (
        <StyledLabel textColor={borderColor} className="label" htmlFor={id}>
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        height={height}
        MenuProps={{ autoFocus: false }}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        onClose={() => {
          if (onBlur) onBlur();
        }}
        onBlur={onBlur}
        displayEmpty
        renderValue={!value || newOptions.length === 0 ? () => <span>{placeholder}</span> : undefined}
        disabled={disabled}
        error={!!errorMessage && !value}
        label={value && label}
        id={id}
      >
        {newOptions.map((option) => (
          <StyledMenuItem key={option.value} value={option.value} selected={value === option.value}>
            {option.label}
            <CheckIcon style={{ justifyContent: 'end' }} fill={defaultColors.white.main} />
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {!!errorMessage && !value && <StyledErrorText>{errorMessage}</StyledErrorText>}
    </FormControl>
  );
};

export default CustomSelect;
